<!--电能设置--计量对象组---->
<template>
  <div id="measuregroup" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="side">
      <div style="display: flex; justify-content: space-between;padding-right:5px;">
          <!-- <div class="header-title">
            {{ $t("energy.setting.reportGr.yearly.name") }}
          </div> -->

          <div class="header-title">
          <!-- 计量对象组 -->
          {{name}}
        </div>
          <div class="mg-add">
            <a-icon type="plus-circle" style="vertical-align: 0.3rem;" @click="addClick" :title="$t('energy.enedata331')"/>
          </div> 
      </div>
      <div class="side-menu">
      <a-menu
        style="width: 256px"
        mode="inline"
        :inline-collapsed="collapsed"
        :open-keys.sync="openKeys"
        :default-open-keys="['0']"
        @click="handleClick"
      >
        <template v-for="item in measureGroupList">
          <a-menu-item v-if="!item.children" :key="item.no">
            <a-icon type="pie-chart" />
            <span>{{ item.text }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.no" :menu-info="item" :fatherMethod="deleteClick"/>
        </template>
         
      </a-menu>
      </div>
          <!-- <img  src="../../../../public/images/icon/538.png" alt="" class="mg-add-img" @click="addClick" title="添加计量对象组"> -->
        <!-- <div class="mg-add">
          <a-icon type="plus-circle" style="vertical-align: 0.3rem;" @click="addClick" :title="$t('energy.enedata331')"/>
        </div>  -->
      </div>
    <div class="mg-main">
      
      <!-- 条件选择2 -->
      <div class="condition">
        <div class="display-column">
          <!-- 名称 -->
          <span>{{$t('energy.enedata164')}}</span>
          <div>
            <a-input v-model="measureGroupDetail.selGroupName" style="width: 200px;" :read-only="readonly"></a-input>
            &nbsp;
            <a-button  shape="circle" icon="edit"  @click="updateClick" :disabled="!selChange"  />
          </div>
        </div>
        <div class="display-column">
          <!-- 单位 -->
          <span>{{$t('energy.enedata165')}}</span>
           <a-select v-model="measureGroupDetail.unit" style="width: 200px;" @change="handleChange">
            <a-select-option
              v-for="(itme, index) in measureGroupDetail.unitList"
              :key="index"
              :value="itme.no"
            >
              {{ itme.text }}
            </a-select-option>
          </a-select>
        </div>
      </div>
     <!-- 计量对象区分 -->
      <div class="content">
        <div  class="box box5">
             <div class="box-title">{{$t('energy.enedata295')}}5</div>
              <div class="box-item">
              <a-select v-model="measureGroupDetail.point5" style="width: 360px;margin-top: -5px;" @change="handleChange">
                <a-select-option
                  v-for="(itme, index) in measureGroupDetail.pointList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
              </a-select>
              </div>
        </div>
      </div>
      <div class="content">
        <div  class="box box4">
             <div class="box-title">{{$t('energy.enedata295')}}4</div>
              <div class="box-item">
              <a-select v-model="measureGroupDetail.point4" style="width: 360px;margin-top: -5px;" @change="handleChange">
                <a-select-option
                  v-for="(itme, index) in measureGroupDetail.pointList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
              </a-select>
              </div>
        </div>
      </div>
      <div class="content">
        <div  class="box box3">
             <div class="box-title">{{$t('energy.enedata295')}}3</div>
              <div class="box-item">
              <a-select v-model="measureGroupDetail.point3" style="width: 360px;margin-top: -5px;" @change="handleChange">
                <a-select-option
                  v-for="(itme, index) in measureGroupDetail.pointList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
              </a-select>
              </div>
        </div>
      </div>
      <div class="content">
        <div  class="box box2">
             <div class="box-title">{{$t('energy.enedata295')}}2</div>
              <div class="box-item">
              <a-select v-model="measureGroupDetail.point2" style="width: 360px;margin-top: -5px;" @change="handleChange">
                <a-select-option
                  v-for="(itme, index) in measureGroupDetail.pointList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
              </a-select>
              </div>
        </div>
      </div>
      <div class="content">
        <div  class="box box1">
             <div class="box-title">{{$t('energy.enedata295')}}1</div>
              <div class="box-item">
                <a-select v-model="measureGroupDetail.point1" style="width: 360px;margin-top: -5px;" @change="handleChange">
                <a-select-option
                  v-for="(itme, index) in measureGroupDetail.pointList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
              </a-select>
              </div>
        </div>
      </div>       

      
      <div class="display-center button">
        <a-button type="primary" @click="loadMeasureGroup(0)" style="margin-right:5px;">{{$t('energy.enedata128')}}</a-button>
        &nbsp;&nbsp;
         <a-button type="primary" :disabled="!dataChange" @click="setMeasureGroupDetail">{{$t('energy.enedata023')}}</a-button>
      </div>
    </div>

   <div>
      <a-modal v-model="visible" :title="name" @ok="handleOk">
        <a-form-model ref="measureGroupForm" 
            :model="form" 
            :rules="rules"
            :label-col="formItemLayout.labelCol" 
            :wrapper-col="formItemLayout.wrapperCol"  >
                
            <a-form-model-item  ref="newMeasureGroupName" prop="newMeasureGroupName">
                  <span slot="label">
                    {{$t('energy.enedata164')}}&nbsp;
                    <a-tooltip :title='disallowed'>
                    <a-icon type="question-circle-o" />
                    </a-tooltip>
                </span>
                <a-input 
                    v-model="form.newMeasureGroupName"
                    @blur="
                    () => {
                        $refs.newMeasureGroupName.onFieldBlur();
                    }
                    "
                    :placeholder="$t('energy.enedata302')"
                />
            </a-form-model-item>
                
        </a-form-model>
        <!-- <a-form :form="form">
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
          >
            <span slot="label">
                                名称&nbsp;
                    <a-tooltip title='禁止输入以下特殊字符[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】'>
                    <a-icon type="question-circle-o" />
                    </a-tooltip>
                </span>
            <a-input              
              v-decorator="[
                'newMeasureGroupName',
                {rules: [{ required: true, message: '请输入计量对象组名称', trigger: 'blur' },
                {min: 1, max: 30, message: '计量对象组名称字数限制在1~50之内。', trigger: 'blur'}] },
              ]"
              placeholder="请输入计量对象组名称"
              style="width:25rem;"
            />
          </a-form-item> 
        </a-form>-->

      </a-modal>
    </div>
  </div>
</template>

<script>
import { getEneMeasureGroup , getEneMeasureGroupDetail } from "../../../api/energy";
import { fncInputChk } from "../../../common/common";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";

const formItemLayout = {
  labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
  },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
  components: {
    'sub-menu': SubMenu,
  },
  data() {
 let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t("enedata305")));
        } else {
        callback();
      }
    };
     return {
      readonly:true,
      dataChange:false,
      selChange:false,
      visible:false,
      loading:false,
      collapsed: false,
      rootSubmenuKeys: ["0"],
      openKeys: ["0"],
      name:this.$t('energy.enedata304'),
      measureGroupList:[],
      measureGroupDetail:{unitList:[],pointList:[],},
      measureGroupId:0,
      newMeasureGroupName:'',
      updateMeasureGroupId:'0',
      formItemLayout,
      formTailLayout,
      rules: {
            newMeasureGroupName: [
                { required: true, message: this.$t('energy.enedata302'), trigger: 'blur' },//'请输入计量对象组名称'
                { min: 1, max: 30, message: this.$t('energy.enedata303'), trigger: 'blur' },//'计量对象组名称长度必须在 1 到 30之间'
                { validator:normalName, trigger: 'blur' },
            ]},
      form:{newMeasureGroupName:''},
      disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadMeasureGroup(0);
    }
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val);
    },
    "$store.getters.clientId":{
      handler(val){
        console.log(val);
        if(val!==""){
          this.loadMeasureGroup(0);
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods: {
    
     deleteClick(e,no,text) {
      
      if(this.measureGroupId==no){
        this.measureGroupId = "0";
      }
      console.log('deleteClick', e,no,text);
      let msg =  this.$t('energy.enedata301');
      msg = msg.replace('${text}',text);
      this.$confirm({
        title:msg,//'确认要删除计量对象组【'+text+'】吗？',
        centered: true,
        onOk: () => {
              this.loading = true;
              let params = {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                groupNo: no
              }
             console.log(params);
             getEneMeasureGroup(params)
              .then((res) => {
                console.log("DeleteMeasureGroup Result",res);
               
                if(res.errorCode == '00'){
                  this.$message.success(res.msg);
                  this.loadMeasureGroup();
                }else{
                  this.$message.error(res.msg);
                  this.loading = false;
                }      
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
             }
        });
    },
     handleOk(e) {
      console.log(e);

      this.$refs.measureGroupForm.validate(valid => {
          if (valid) {
              this.saveMeasureGroup(this.updateMeasureGroupId,this.form.newMeasureGroupName);
          } else {
              console.log('error submit!!');
              return false;
          }
      });
    },
    saveMeasureGroup(no,name){
      this.$confirm({
        title: this.$t('energy.enedata300'),//'确认要保存所设置的计量对象组名称吗？',
        centered: true,
        onOk: () => {
              this.loading = true;
              let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                groupNo: no,
                name: name
              }
             console.log(params);
             getEneMeasureGroup(params)
              .then((res) => {
                console.log("SaveMeasureGroup Result",res);
               
                if(res.errorCode == '00'){
                   this.$message.success(res.msg);
                  if(parseInt(no)>0){
                    this.measureGroupDetail.selGroupName = name;
                  }
                   this.loadMeasureGroup(0);
                   this.visible = false; 
                }else{
                  this.$message.error(res.msg);
                  this.loading = false;
                }      
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
             }
        });
    },
    handleClick(e) {
      console.log('handleClick', e);
      this.measureGroupId = e.key;
      if(parseInt(this.measureGroupId)>0){
        this.selChange = true;
      }
      console.log('measureGroupId', this.measureGroupId);
      this.loadMeasureDetail();
    },
    addClick(e) {
      console.log('addClick', e);
      this.form.newMeasureGroupName = '';
      this.updateMeasureGroupId = '0';
      this.visible = true;
      if(this.$refs.measureGroupForm){
        this.$refs.measureGroupForm.resetFields();
      }
    },
    updateClick() {
      console.log('updateClick', this.form);
      this.form.newMeasureGroupName = this.measureGroupDetail.selGroupName;
      this.updateMeasureGroupId = this.measureGroupDetail.selGroupNo;
      this.visible = true;
      if(this.$refs.measureGroupForm){
        this.$refs.measureGroupForm.resetFields();
      }
    },
    titleClick(e) {
      console.log('titleClick', e);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    loadMeasureDetail() {
       if(parseInt(this.measureGroupId)>0){
         let params =  {
              action:0,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0,
              measureGroupNo:this.measureGroupId,
          };
         console.log("loadMeasureDetail Params",params);
         this.loading = true;
         getEneMeasureGroupDetail(params)
        .then((res) => {
          console.log("loadMeasureDetail Result",res);
          this.loading = false;
          if(res.errorCode == '00'){
            this.measureGroupDetail = res.data;
          }
          
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
       }
    },
    setMeasureGroupDetail(){
      if(parseInt(this.measureGroupId)>0){
          let params =  {
              action:1,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0,
              measureGroupNo:this.measureGroupDetail.selGroupNo,
          };
          params.unit=this.measureGroupDetail.unit;
          params.point1=this.measureGroupDetail.point1;
          params.point2=this.measureGroupDetail.point2;
          params.point3=this.measureGroupDetail.point3;
          params.point4=this.measureGroupDetail.point4;
          params.point5=this.measureGroupDetail.point5;

          console.log("setMeasureGroupDetail Params",params);
          this.$confirm({
            title: this.$t('energy.enedata299'),//'确认要保存所设置的计量对象组详细信息吗？',
            centered: true,
            onOk: () => {
                this.loading = true;
                  getEneMeasureGroupDetail(params)
                  .then((res) => {
                    console.log("setMeasureGroupDetail Result",res);
                    this.loading = false;
                    if(res.errorCode == '00'){
                      this.loadMeasureGroup(0);
                    }
                  })
                  .catch((err) => {
                    this.loading = false;
                    console.log(err);
                  });
            },
          });
      }
    },
    loadMeasureGroup(action){
      let params =  {
          action:0,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
      console.log("loadMeasureGroup Params",params);
      this.loading = true;
      getEneMeasureGroup(params)
      .then((res) => {
        console.log("loadMeasureGroup Result",res);
        if(res.errorCode == '00'){
          this.measureGroupList = res.data.measureGroupList;
        }
        this.loadMeasureDetail();      
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    handleChange(){
      if(parseInt(this.measureGroupId)>0){
        this.dataChange = true;
      }
    },
  }
};
</script>

<style scoped>
#measuregroup {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul,
li {
  list-style: none;
  margin: 0;
}
.ant-menu-submenu-title{
  border-bottom: 1px solid #eeeff3;
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.header-title::before {
  content: "";
  display: inline-block;
  height: 30px;
  width: 4px;
  background: #7585cd;
  margin-right: 20px;
}

.side {
  width: 280px;
  height: calc( 100% - 1px );
}
.side-menu{
  max-height: calc( 100% - 50px );
  overflow: auto;
}
.mg-main {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 20px;
}
.mg-add{
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 22px;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin-top: 5px;
}
.mg-add:hover{
  color: #FFFFFF;
  background-color: #7682ce;
}
.mg-add-img{
  width: 24px;
  cursor: pointer;
}
.condition{
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}
.content{
  border-radius: 3px;
  box-shadow: 0 0 10px #eeeff3;
  margin: 8px;
  padding: 20px;
}
.box{
  height: 20px;
  line-height: 20px;
  text-align: left;
  vertical-align: middle;
  display:flex;
  justify-content:flex-start;
}

.box::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 20px;
  margin-right: 20px;
  background-color: #7682ce;
}
.box1::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 20px;
  margin-right: 20px;
  background-color: #ea5513;
}
.box2::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 20px;
  margin-right: 20px;
  background-color: #f8b62c;
}
.box3::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 20px;
  margin-right: 20px;
  background-color:#24b34a;
}
.box4::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 16px;
  margin-right: 20px;
  background-color: #00a1e9;
}
.box5::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 20px;
  margin-right: 20px;
  background-color: #7682ce;
}
.box-title{
  margin-right: 16px;
  height: 20px;
  line-height: 16px;
  font-size: 18px;

}
.box-item{
  margin-top: -2.4px;
}

.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.display-center {
  display: flex;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
}
.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.button{
  padding: 30px 0;
}
.button button{
  width: 120px;
}
</style>